import { useCallback } from 'react';
import { asyncDebounce } from '../fp';

/**
 * @param {Function} fn - function to create a higher order function from
 * @param {number} [wait=0] - The number of milliseconds to delay
 * @param {Object} [options]
 * @param {boolean} [options.trailing=true] - Specify invoking on the trailing edge of the timeout.
 * @param {boolean} [options.leading=false] - Specify invoking on the leading edge of the timeout.
 */
export function useAsyncDebounce(fn, wait, options) {
  return useCallback(asyncDebounce(fn, wait, options), [
    fn,
    wait,
    options.trailing,
    options.leading,
  ]);
}
