import { useEffect, useRef, useState } from 'react';

/**
 * A setTimeout hook that calls a callback after a timeout duration
 * @param cb The callback to be invoked after timeout
 * @param timeoutDelayMs Amount of time in ms after which to invoke
 */
export function useTimeout(cb, timeoutDelayMs = 0) {
  const [isTimeoutActive, setIsTimeoutActive] = useState(false);
  const savedRefCallback = useRef();

  useEffect(() => {
    savedRefCallback.current = cb;
  }, [cb]);

  function clear() {
    setIsTimeoutActive(false);
  }
  function start() {
    setIsTimeoutActive(true);
  }
  function callback() {
    // eslint-disable-next-line no-unused-expressions
    savedRefCallback?.current();
    clear();
  }

  useEffect(() => {
    if (isTimeoutActive) {
      const timeout = window.setTimeout(callback, timeoutDelayMs);
      return () => {
        window.clearTimeout(timeout);
      };
    }
    return () => {};
  }, [isTimeoutActive]);

  return {
    clear,
    start,
    stop: clear,
    isActive: isTimeoutActive,
  };
}
