import { useEffect, useRef, useState } from 'react';

export function useOnScreen({ rootMargin = '0px', threshold = 0 } = {}) {
  const ref = useRef();
  const [inView, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
        threshold,
      },
    );

    const node = ref.current;
    if (node) {
      observer.observe(node);
      return () => observer.unobserve(node);
    }
  }, []);

  return { ref, inView };
}
