import { useEffect } from 'react';

export function useFreshDeskDisplayStyle(value) {
  useEffect(() => {
    if (document.querySelector('#launcher-frame')) {
      const selector = document.querySelector('#launcher-frame');

      if (value === 'none') {
        selector.style.display = 'none';
      } else {
        selector.style.display = 'block';
      }
    }
  }, [value]);
}
