import { useEffect } from 'react';

/**
 * Performs the action if still mounted after delay ms
 */
export const useDelayedAction = (delay, action) => {
  useEffect(() => {
    const timeout = setTimeout(action, delay);
    return () => clearTimeout(timeout);
  }, [delay, action]);
};
