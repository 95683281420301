import { useCallback, useEffect, useState } from 'react';

interface IUsePersistedStateParams<T> {
  defaultValue: T;
  key: string;
  storage?: Storage;
}

const usePersistedState = <T>({
  defaultValue,
  key,
  storage,
}: IUsePersistedStateParams<T>): [T, (value: T) => void] => {
  // needed for SSR (window does not exist)
  const getStorage = useCallback(() => storage ?? window.localStorage, [
    storage,
  ]);

  const [value, setValue] = useState(() => {
    if (!isBrowser()) {
      return;
    }

    const persistedValue = getStorage().getItem(key);
    return persistedValue !== null ? JSON.parse(persistedValue) : defaultValue;
  });

  useEffect(() => {
    getStorage().setItem(key, JSON.stringify(value));
  }, [key, value, getStorage]);

  return [value, setValue];
};

function isBrowser() {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

export { usePersistedState };
