import { useCallback, useEffect, useState } from 'react';

/**
 * Copy a text value to the clipboard
 * Feature detection is handled for newer browsers defaulting to polyfill if not present.
 *
 * @param {string} text - The text that will be copied to the clipboard
 * @param {object} [ref] - This is only needed for backwards compatibility, when using polyfill an element is used as a copy target.
 * Due to trapFocus this could break. ref provides a way to choose where the elements gets rendered to avoid trapFocus
 * @returns {[boolean, Function]}
 */
export const useCopyToClipboard = (text, ref) => {
  const [copied, setCopied] = useState(false);

  const copy = useCallback(async () => {
    try {
      const copyAdapter = hasClipboard() ? navigatorAdapter : polyfillAdapter;
      return await copyAdapter(text, ref).then(success => {
        setCopied(success);
        return success;
      });
    } catch {}
  }, [text, ref]);

  useEffect(() => {
    return () => setCopied(false);
  }, [text, ref]);

  return [copied, copy];
};

/**
 * @param {string} str
 */
const polyfillAdapter = async (str, ref) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  const container = ref?.current ? ref.current : document.body;
  container.appendChild(el);

  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;

  el.select();

  const success = document.execCommand('copy');

  container.removeChild(el);

  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }

  return success;
};

/**
 * @param {string} text
 */
const navigatorAdapter = async text => {
  await navigator.clipboard.writeText(text);
  return true;
};

const hasClipboard = () =>
  Boolean(
    (window?.navigator?.clipboard?.writeText &&
      window.location.protocol === 'https:') ||
      window.location.host.includes('localhost:3000'),
  );
