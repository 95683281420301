/**
 * @param {string} domain - must be a valid domain as from window.location.origin
 * @param {string} path - a sub path on the domain, to prevent accidental usage it is required
 */
export const toSafeRedirect = (domain, path) => {
  if (!domain || !path) return null;

  try {
    const url = new URL(domain + '/' + path.slice(1));
    return url.toString();
  } catch (e) {
    return null;
  }
};

export const logoutPathFrom = previousPath =>
  previousPath === '/studies' ? '/login' : `/login?redirectTo=${previousPath}`;
