import type { FetchResult } from '@apollo/client';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

type ConfigurationScope = 'DEFAULT' | 'GLOBAL' | 'STUDY';

type StudyConfigurationScope = {
  studyId: string;
};

type ConfigurationOverrideItem = {
  scope: ConfigurationScope;
  value: string;
};

type ConfigurationEntry = {
  name: string;
  value: string;
  configurationOverrideChain: ConfigurationOverrideItem[];
};

export type StudySettings = {
  value: string;
  overrideChain: ConfigurationOverrideItem[];
  loading: boolean;
};

export const GET = gql`
  query WEB_useConfiguration($name: String!, $scope: StudyConfigurationScope) {
    studyConfiguration(name: $name, filter: $scope) {
      name
      value
      configurationOverrideChain {
        value
        scope
      }
    }
  }
`;

export const SET = gql`
  mutation WEB_setConfiguration($input: setStudyConfiguration!) {
    setStudyConfiguration(input: $input) {
      name
      value
    }
  }
`;

export const UNSET = gql`
  mutation WEB_unsetConfiguration($input: unsetStudyConfiguration!) {
    unsetStudyConfiguration(input: $input) {
      name
      value
    }
  }
`;

type UseConfigurationProps = {
  name: string;
  scope: StudyConfigurationScope;
  defaultValue?: string;
};

type UseConfigurationResult = [
  StudySettings,
  (value: any) => Promise<FetchResult>,
  () => Promise<FetchResult>,
];

export const useConfiguration = ({
  name,
  scope,
  defaultValue = null,
}: UseConfigurationProps): UseConfigurationResult => {
  // GET
  const { data, loading } = useQuery(GET, {
    variables: { name, scope },
    fetchPolicy: 'network-only',
  });

  // SET
  const [setStudyConfiguration] = useMutation(SET);
  const executeSetConfiguration = useCallback(
    value =>
      setStudyConfiguration({
        variables: { input: { name, scope, value: stringify(value) } },
      }),
    [name, scope, setStudyConfiguration],
  );

  // UNSET
  const [unsetStudyConfiguration] = useMutation(UNSET);
  const executeUnsetConfiguration = useCallback(
    () =>
      unsetStudyConfiguration({
        variables: { input: { name, scope } },
      }),
    [name, scope, unsetStudyConfiguration],
  );

  return [
    getStudySettings(data, defaultValue, loading),
    executeSetConfiguration,
    executeUnsetConfiguration,
  ];
};

export const getStudySettings = (
  data: { studyConfiguration: ConfigurationEntry },
  defaultValue: string,
  loading: boolean,
): StudySettings => {
  const value = data?.studyConfiguration?.value;
  const overrideChain = data?.studyConfiguration?.configurationOverrideChain;

  let studySettingsValue: string;
  let studySettingsOverrideChain: ConfigurationOverrideItem[];

  if (!value) studySettingsValue = defaultValue;
  else {
    studySettingsValue = parse(value);
  }
  if (!overrideChain) studySettingsOverrideChain = [];
  else {
    studySettingsOverrideChain = overrideChain;
  }

  return {
    value: studySettingsValue,
    overrideChain: studySettingsOverrideChain,
    loading,
  };
};

export const parse = value => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const stringify = value => {
  return typeof value === 'string' ? value : JSON.stringify(value);
};
